.font-fifteen {
  font-size: 15px;
  color: black;
  text-align: center;
  margin-bottom: 0;
}

.text-link {
  color: #1b5bff;
  font-size: 15px;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}

.flex-gap {
  display: flex;
  gap: 5px;
}

.button {
}

.my-swal-center {
  /* top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important; */
  position: fixed !important;
  z-index: 1050 !important;
}

.img {
  height: 100%;
}

/* div:where(.swal2-container) {
  padding-left: 5rem;
  padding-right: 5rem;
} */
div:where(.swal2-container) div:where(.swal2-popup) {
  margin: auto;
  margin-top: 200px;
}
